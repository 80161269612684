
  import { defineComponent } from 'vue';
  import { useQuery } from '@vue/apollo-composable';
  import { useRoute } from 'vue-router';

  import gql from 'graphql-tag';

  import Constants from '@/constants';
import { useSecurityStore } from '@/modules/security/store';

  export default defineComponent({
    setup() {
      const route = useRoute();

      const securityStore = useSecurityStore();

      const { result, loading } = useQuery(gql`
        query getCollection($collectionId: ID!) {
          collection(id: $collectionId) {
            type
            title
            objects {
              id
              files(reference: "front") {
                id
                width
                height
              }
            }
          }
        }
      `, {
        collectionId: route.params.collectionId,
      });

      const getUrlForFile = (file: any): string => {
        return `${Constants.CDN_URL}/${file.id}/thumbnail/${securityStore.authenticationToken}`;
      };

      const getRatioForFile = (file: any): number => {
        return (file.height / file.width) * 100;
      };

      const isMarkedDone = (id: string): boolean => {
        const objectIds = JSON.parse(window.localStorage.getItem('objects_done') ?? '[]');

        return objectIds.indexOf(id) !== -1;
      };

      return {
        getUrlForFile,
        getRatioForFile,

        loading,
        result,

        isMarkedDone,
      };
    },
  });
