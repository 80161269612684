import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-52014506")
const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = { style: {"margin-bottom":"32px"} }
const _hoisted_3 = {
  class: "objects",
  style: {"columns":"4"}
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_layout_default = _resolveComponent("v-layout-default")!

  return (_openBlock(), _createBlock(_component_v_layout_default, null, {
    default: _withCtx(() => [
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.result.collection.title), 1),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.result.collection.objects, (object) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  to: { name: 'object', params: { objectId: object.id } },
                  class: _normalizeClass([{ done: _ctx.isMarkedDone(object.id) }, "objects__object"]),
                  style: {"display":"block","width":"100%","margin":"16px"}
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      style: _normalizeStyle([{"display":"inline-block","width":"100%","background-size":"cover"}, {
                'background-image': `url(${_ctx.getUrlForFile(object.files[0])})`,
                'padding-bottom': `${_ctx.getRatioForFile(object.files[0])}%`,
              }])
                    }, null, 4)
                  ]),
                  _: 2
                }, 1032, ["to", "class"]))
              }), 256))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}